import React, { useState } from 'react'
import Header from './Header'
import { TextField,Grid, Typography,Button} from '@mui/material'
import axios from 'axios';
import { Api_url } from './helper';

function Update() {
	const [bookname, setBookname] = useState(''); 
	const [amazonlink, setAmazonlink] = useState('');
	const [review, setReview] = useState('');
	const [image, setImage] = useState(null);
	const handleSubmit = async() => {
		const response = await axios.post(`${Api_url}/sign/update/${bookname}`);
		console.log(response.data)
		setImage(response.data.image)
	}
	const handleUpdate = async () => {
		try {
		  const response = await axios.post(`${Api_url}/sign/updated`, {
			bookname,
			review,
			amazonlink
		  });
			console.log(response.data);
			setBookname('')
			setReview('')
			setAmazonlink('')
		} catch (error) {
		  console.error('Error updating:', error);
		}
	  };
	return (
	  
	  <div>
		  <Header />
		  <Grid container lg={12} sx={{background:'#EFEFEF',minHeight:'90vh'}}>
				<Grid container lg={10} sx={{ margin: 'auto',display:'flex', }}>
					<Grid container lg={6} sx={{justifyContent:'center'}}>
					<Grid item lg={7}>
					<Typography>BookId</Typography>
					</Grid>
					<Grid item lg={7}>
					<TextField value={bookname} onChange={(e) => setBookname(e.target.value)}  variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
					</Grid>
					<Grid item lg={7}>
					<Button onClick={handleSubmit}        style={{ marginBottom: '76px', marginTop: '36px', fontSize: '18px', fontWeight: '700px', lineHeight: '32px', color: '#fff', backgroundColor: '#005B9D', textTransform: 'none', fontFamily: 'Space Grotesk' }}
             >Submit </Button>
					</Grid>
					</Grid>
					
				  
				
					
				</Grid>
				<Grid container lg={12}>
					{image && <>
						<Grid container lg={6} sx={{display:'flex',justifyContent:'center'}}>
							<img src={image} style={{width:'450px',height:'auto'}} />
						</Grid>
						<Grid container lg={6}>
							<Grid item lg={12}>
							<Typography sx={{ fontSize: '56px', fontWeight: '700', color: '#005B9D', fontFamily: 'Space Grotesk', marginTop: '76px' }}>Update Form</Typography>	
							</Grid>
							<Grid item lg={10}>
							<Typography>Amazon</Typography>
								<TextField value={amazonlink} onChange={(e) => setAmazonlink(e.target.value)} placeholder='Provide your link here'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
							</Grid>
							<Grid item lg={10}>
						<Typography>BookReviewCount</Typography>
						<TextField value={review} onChange={(e) => setReview(e.target.value)} variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
							</Grid>
							<Grid item lg={12}>
								<Button onClick={handleUpdate}        style={{ marginBottom: '76px', marginTop: '36px', fontSize: '18px', fontWeight: '700px', lineHeight: '32px', color: '#fff', backgroundColor: '#005B9D', textTransform: 'none', fontFamily: 'Space Grotesk' }}
             >Submit </Button>
								</Grid>
						
						
						</Grid>
						
					</>}
				</Grid>
		  </Grid>
		  </div>
  )
}

export default Update