import logo from './logo.svg';
import './App.css';
import Panel from './Panel';
import {Route,Routes} from 'react-router-dom';
import Login from './Login1';
import Sign from './sign'
import Forget from './Forget';
import Upload from './Upload';
import Onetime from './Onetime';
import Reset from './Reset';
import Request from './Request';
import { Records } from './Records';
import AcceptedBooksTable from './Accepted';
import Update from './Update';
function App() {
  return (
    <>
    <Routes>
   <Route path='/' element={<Panel/>}/>
			 
			  <Route path='/Sign'element={<Sign/>}/>
     <Route path='/login'element={<Login/>}/>
   <Route path='/Forget' element={<Forget/>}/>
   <Route path='/Onetime' element={<Onetime/>}/>
   <Route path='/Reset' element={<Reset/>}/>
			  <Route path='/Request' element={<Request />} />
			  <Route path='/Records' element={<Records />} />
			  <Route path='/Upload' element={<Upload />} />
			  <Route path='/payment' element={<AcceptedBooksTable />} />
			  <Route path='/update' element={<Update/>}/>
			  
   </Routes>
    </>
  );
}

export default App;
