import React from 'react'
import {Button, Grid, Typography} from '@mui/material'
import Login1 from './Images/Login1.png'
import {Link} from 'react-router-dom'
import Header from './Header'
const Panel = () => {
	const token=localStorage.getItem('token')
  return (
	  <>
		  <Header/>
    <Grid container lg={12} >
        <Grid container lg={10} sx={{margin:'auto'}}>
<Grid container lg={12} sx={{backgroundColor:'#F8F5F0',marginTop:'86px',}}>
    <Grid container lg={6}>
        <Grid item lg={12} sx={{display:'flex',justifyContent:'end'}}>
        <img src={Login1} style={{width:'374px',height:'374px'}}>

</img>
        </Grid>

    </Grid>
					  {token ? "" : <Grid container lg={6}>
						  <Grid item lg={12}>
							  <Typography sx={{ color: '#053F5C', fontSize: '36px', fontWeight: '700', marginTop: '130px' }}>
								  Welcome Back!
							  </Typography>


							  <Typography sx={{ color: '#053F5C', fontSize: '20px', fontWeight: '400', marginTop: '12px' }}>
								  Login to check requests
							  </Typography>
						  </Grid>
						 
						  <Grid item lg={5}>
							  <Link to='/sign'>
								  <Button sx={{ textTransform: 'none', backgroundColor: '#053F5C', fontSize: '18px', fontWeight: '700', lineHeight: '32px', color: '#fff', fontFamily: 'Space Grotesk', height: '42px', padding: '14px 30px', marginTop: '56px', marginBottom: '130px', borderRadius: '6px', '&:hover': { backgroundColor: '#053F5C' } }}>
									  Signup
								  </Button>
							  </Link>
						  </Grid>
						  <Grid item lg={5}>
							  <Link to='/login'>
								  <Button sx={{ textTransform: 'none', backgroundColor: '#053F5C', fontSize: '18px', fontWeight: '700', lineHeight: '32px', color: '#fff', fontFamily: 'Space Grotesk', height: '42px', padding: '14px 30px', marginTop: '56px', marginBottom: '130px', borderRadius: '6px', '&:hover': { backgroundColor: '#053F5C' } }}>
									  Login
								  </Button>
							  </Link>
						  </Grid>
					  </Grid>}
</Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default Panel