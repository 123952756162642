import React, { useState,useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Image from 'mui-image';
import Rmlogo from './Images/Rmlogo.png';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom'
import { Api_url } from './helper';
import Tooltip from '@mui/material/Tooltip';

// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout'
//  // Import the AccountMenu component
// import '@fontsource/space-grotesk';

const Header = () => {
    
    const token=localStorage.getItem('token')
    
  // State to track the login status
  const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
	};
	
	
  const handleClose = () => {
    setAnchorEl(null);
	};
	
	const [photo1,setPhoto1]=useState(null)
	const fetchPhoto = async (e) => {
		const token = localStorage.getItem('token');
		if (token) {
			const userId = jwtDecode(token);
			console.log(userId, 'userid');
			const userId1 = userId._id;
		
			try {
				const response = await axios.get(`${Api_url}/sign/photo`, {
					params: {
						userId: userId1
					}
				});
				console.log(response.data);
				setPhoto1(response.data);
			} catch (error) {
				console.error('Error fetching photo:', error);
				// Handle error appropriately
			}
		}
	};
	useEffect(() => {
     
        fetchPhoto();
       
    }, []);

  // Function to handle logout
  const handleLogout = () => {
    // Perform logout logic, e.g., clear token, etc.
    setIsLoggedIn(false);
  };
  const handleLogout1 = () => {
    // Clear the token from local storage
    localStorage.removeItem('token');
    // Update the login status
	  setIsLoggedIn(false);
	  navigate('/')
	};

	const handlehome = () => {
		navigate('/request')
	}
	const handlehome1 = () => {
		navigate('/Request')
	}
	const handlereview = () => {
		navigate('/Records')
	}
	const handleAbout = () => {
		navigate('/Upload')
	}
	const handleContact = () => {
		navigate('/payment')
	}
	const handleupdate = () => {
		navigate('/update')
	}

  
  return (
    <>
      <Grid container lg={12} sx={{ marginTop: '12px' }}>
        <Grid container lg={10} sx={{ margin: 'auto' }}>
          <Grid container lg={8}>
            <Grid item lg={2}>
			<img src={Rmlogo} onClick={handlehome} style={{width:'107px',height:'76px'}} />
            </Grid>
            <Grid item lg={10} sx={{ justifyContent: "end", display: 'flex', alignItems: 'center' }}>
              <Grid item lg={2}>
                <Typography onClick={handlehome1} sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px', color: '#000', fontFamily: 'Space Grotesk',cursor:'pointer' }}>
                  Requests
                </Typography>
              </Grid>
              <Grid item lg={2.3}>
                <Typography onClick={handlereview} sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px', color: '#000', fontFamily: 'Space Grotesk',cursor:'pointer' }}>
                  Records
                </Typography>
              </Grid>
              <Grid item lg={2}>
                <Typography onClick={handleAbout} sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px', color: '#000', fontFamily: 'Space Grotesk',cursor:'pointer' }}>
                  Upload
                </Typography>
              </Grid>
              <Grid item lg={2}>
                <Typography onClick={handleContact} sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px', color: '#000', fontFamily: 'Space Grotesk',cursor:'pointer' }}>
                  Accepted
                </Typography>
						  </Grid>
						  <Grid item lg={2}>
                <Typography onClick={handleupdate} sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px', color: '#000', fontFamily: 'Space Grotesk',cursor:'pointer' }}>
                  Update
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {token?<Grid container lg={4}>
           <Grid item lg={12} sx={{display:'flex',justifyContent:'end'}}>
           <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
							  >
								  {
									  photo1 ? (
										  <img
											  src={photo1}
											  alt="Photo"
											  style={{
												  width: '56px',
												  height: '56px',
												  borderRadius: '56px',
                   
												  objectFit: 'cover',
												  margin: 'auto', // Center the image horizontally
												  display: 'block', // Ensure the image is centered correctly
											  }}
										  />
									  ) : (
										  <Avatar sx={{ width: 56, height: 56 }}>S</Avatar>
									  )
								  }
          
          </IconButton>
        </Tooltip>

        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: '60px',
              height: '60px',
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
        <Link to='/MyProfile' style={{textDecoration:'none',color:'#1e1e1e',fontFamily:'Space Grotesk',fontSize:'16px',fontWeight:'400'}}>  My Profile
        </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <Link to='/MyAccount' style={{textDecoration:'none',color:'#1e1e1e',fontFamily:'Space Grotesk',fontSize:'16px',fontWeight:'400'}}>  
        My account  </Link>
        </MenuItem>
        
        <MenuItem onClick={handleClose}>
        <Link to='/MyList' style={{textDecoration:'none',color:'#1e1e1e',fontFamily:'Space Grotesk',fontSize:'16px',fontWeight:'400'}}>  
        My List </Link>
       
        </MenuItem>
        <MenuItem onClick={handleLogout1}>
  Logout
</MenuItem>

      </Menu>
  
            </Grid>

            </Grid>:
          <Grid container lg={4} sx={{ justifyContent: "end", display: 'flex', alignItems: 'center' }}>
          <Grid item lg={4.3}>
            <Link to="/sign" style={{ textDecoration: 'none' }}>
              <Button sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '32px', color: '#0E0D17', border: '2px solid #D6DCE2', textTransform: 'none', borderRadius: '6px', padding: '14px 24px', height: '42px', fontFamily: 'Space Grotesk' }}>Sign Up</Button>
            </Link>
          </Grid>
          <Grid item lg={4}>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '32px', color: '#fff', backgroundColor: '#005B9D', textTransform: 'none', borderRadius: '6px', padding: '14px 30px', height: '42px', fontFamily: 'Space Grotesk', '&:hover': { backgroundColor: '#005B9D' } }}>Log In</Button>
            </Link>
          </Grid>
        </Grid>
        }
        </Grid>
      </Grid>
    </>
  );
};

export default Header;